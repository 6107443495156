import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { PageMapping } from '../../apollo/models/base-models';


const MAPPING_QUERY = gql`query PageMapping($locale: Locale!){
  pages(locales: [$locale, en], where: {category: "info"}) {
    name,
    localizedName,
    category
  }
}`

interface MappingResponse {
  pages: PageMapping[]
}

@Injectable({
  providedIn: 'root'
})
export class PageMappingService {

  constructor(
    private apollo: Apollo,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  private _mapper: BehaviorSubject<Map<string, string>> = new BehaviorSubject<Map<string, string>>(null);

  getMapping() {
    if (!this._mapper.getValue()) this.createStaticPageMapping();
    return this._mapper.asObservable().pipe(
      filter(item => !!item)
    );
  }


  public createStaticPageMapping() {
    return this.apollo
      .watchQuery<MappingResponse>({
        query: MAPPING_QUERY,
        variables: {
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        share()
      ).subscribe(resp => {
        let map = new Map<string, string>();
        resp.data.pages.forEach(elem => {
          map.set(`${elem.category}/${elem.localizedName}`, elem.name)
        });
        this._mapper.next(map);
      })
  }


}
